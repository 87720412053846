import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';

import i18n from 'src/i18n';

import { DownloadOutlined, CloseOutlined, CheckOutlined, CaretDownOutlined, SettingOutlined } from '@ant-design/icons';
import { Layout, Popover, Drawer, Tooltip, Button, Empty } from 'antd';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';

import { OPINION_SORT_TYPE, POST_TYPE, WORD_OF_MOUTH_TYPE, ROUTES } from 'src/consts';
import Logo from 'src/assets/Logo.png';
import UserIcon from 'src/assets/HeaderIcons/UserIcon.svg';
import DropdownIcon from 'src/assets/HeaderIcons/DropdownIcon.svg';
import SearchIconWhite from 'src/assets/SearchIconWhite.svg';
import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';
import TitlePrefixDot from 'src/assets/TitlePrefixDot.svg';
import NoteIcon from 'src/assets/NoteIcon.svg';
import FilterIcon from 'src/assets/FilterIcon.svg';
import RightArrowIcon from 'src/assets/RightArrowIcon.svg';
import packageStore from 'src/stores/packageStore';
import Loading from 'src/assets/loading.svg';

import userStore from 'src/stores/userStore';
import optionsStore from 'src/stores/optionsStore';
import Select from 'src/components/select';
import ScrollTop from '../ScrollTop';
import SideMenu from '../Menu';

import PopoverSelect from '../PopoverSelect';
import SinglePeriodDatePicker from '../SinglePeriodDatePicker';
import SearchTopicCard from '../SearchTopicCard';
import withRouter from '../withRouter';

import CustomizeLayoutViewModel from './viewModel';
import OpinionChartSection from './sections/chart';
import styles from './styles.module.scss';
import './customizeAntd.scss';
import infoHeaderViewModel from '../InfoHeader/viewModel';

const { Header, Content } = Layout;

@observer
class CustomizeLayout extends React.Component {
  constructor() {
    super();

    this.viewModel = new CustomizeLayoutViewModel();
  }

  componentWillUnmount() {
    infoHeaderViewModel.multiViewModel.once?.();
  }

  render() {
    const { router } = this.props;

    return (
      <>
        <Layout
          className={clsx('MenuMain', styles.mainContainer)}
          style={{ height: `${window.innerHeight}px` }}
        >
          <Header className={clsx('header', styles.headerContainer)}>
            <div className={styles.leftHeader}>
              <Link to="/home" className={styles.logoContainer}>
                <img src={Logo} alt="" />
              </Link>
              <div
                className={styles.accountSwitchContainer}
              >
                <div
                  className={styles.textContainer}
                >
                  {/* eslint-disable-next-line no-irregular-whitespace */}
                  {`${i18n.t('common_account')}　/　`}
                </div>
                {
                  userStore.userType !== 'customer'
                    ? (
                      <Popover
                        placement="bottomRight"
                        onOpenChange={this.viewModel.onOpenChange}
                        content={(
                          <div className={styles.switchAccountPopover}>
                            <div className={styles.search}>
                              <div className={styles.searchText}>
                                {i18n.t('common_account')}
                              </div>
                              <div className={styles.searchContainer}>
                                <input
                                  className={styles.searchInput}
                                  placeholder={i18n.t('common_search_placeholder')}
                                  value={this.viewModel.customerKeyword}
                                  onChange={this.viewModel.onCustomerSearchChange}
                                  onKeyDown={this.viewModel.onCustomerSearchKeyDown}
                                />
                                <div
                                  className={styles.searchBtn}
                                  onClick={this.viewModel.onCustomerSearchSubmit}
                                >
                                  <img src={SearchIcon} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className={styles.customerList}>
                              {
                                this.viewModel.searchCustomersRes.length > 0
                                  ? (
                                    <>
                                      {
                                        this.viewModel.searchCustomersRes.map((el) => {
                                          return (
                                            <div
                                              key={el.id}
                                              className={clsx(
                                                styles.customerCard,
                                                el.id === userStore.activeUserId && styles.isActive
                                              )}
                                              onClick={() => {
                                                router.navigate(ROUTES.Home);
                                                userStore.onUserSelect(el);
                                              }}
                                            >
                                              <div className={styles.name}>
                                                <span className={styles.teamName}>
                                                  {el.teamName}
                                                </span>
                                                <span>
                                                  {` - ${el.name}(${el.vocPermission})`}
                                                </span>
                                              </div>
                                              <div className={styles.account}>
                                                {el.account}
                                              </div>
                                            </div>
                                          );
                                        })
                                      }
                                    </>
                                  )
                                  : (
                                    <div className={styles.emptyContainer}>
                                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </div>
                                  )
                              }

                            </div>
                          </div>
                        )}
                      >
                        <div
                          className={styles.activeAccount}
                        >
                          <div className={styles.text}>
                            {userStore.activeUserName}
                          </div>
                          <CaretDownOutlined />
                        </div>
                      </Popover>
                    )
                    : (
                      <div
                        className={styles.activeAccount}
                      >
                        <div className={styles.text}>
                          {userStore.activeUserName}
                        </div>
                      </div>
                    )
                }

              </div>
              {
                userStore.userType !== 'customer'
                && (
                  <Button
                    type="primary"
                    icon={<SettingOutlined />}
                    className={styles.packagesBtn}
                    onClick={() => this.viewModel.onPackageBtnClick(router)}
                  >
                    {i18n.t('packages_button_name')}
                  </Button>
                )
              }
            </div>
            <div className={styles.rightHeader}>
              {
                userStore.search && (
                  <Tooltip
                    title={i18n.t('search_tooltip_content')}
                  >
                    <div className={styles.searchContainer}>
                      <input
                        className={styles.searchInput}
                        placeholder={i18n.t('search_name')}
                        value={this.viewModel.keyword}
                        onChange={this.viewModel.onSearchChange}
                        onKeyDown={this.viewModel.onKeyDown}
                      />
                      <div
                        className={styles.searchBtn}
                        onClick={this.viewModel.onSearchSubmit}
                      >
                        <img src={SearchIconWhite} alt="" />
                      </div>
                    </div>
                  </Tooltip>
                )
              }
              <Popover
                content={(
                  <div className={styles.popoverContainer}>
                    <div className={styles.userInfo}>
                      <div>{userStore.userName}</div>
                      <div>{userStore.userAccount}</div>
                    </div>
                    {
                      userStore.userType === 'customer'
                      && (
                        <div className={styles.userValidity}>
                          <div>{i18n.t('account_validity')}</div>
                          <div>{userStore.userValidity}</div>
                        </div>
                      )
                    }
                    <div className={styles.divider} />
                    <a
                      className={styles.actions}
                      href={this.viewModel.changePasswordUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {i18n.t('account_change_password')}
                    </a>
                    <div className={styles.divider} />
                    <div
                      className={styles.actions}
                      onClick={() => this.viewModel.onLogout(router)}
                    >
                      {i18n.t('account_logout')}
                    </div>
                  </div>
                )}
                placement="bottomRight"
              >
                <div className={styles.userMenuContainer}>
                  <img src={UserIcon} alt="" className={styles.userIcon} />
                  <img src={DropdownIcon} alt="" className={styles.dropdownIcon} />
                </div>
              </Popover>
            </div>
          </Header>
          <Layout>
            <SideMenu />
            <Content className={clsx('main', styles.contentContainer)}>
              {
                !packageStore.isLevelProcessing || [`/${ROUTES.PackagesManage}`, `/${ROUTES.AccountManage}`, `/${ROUTES.Home}`].includes(router.location.pathname)
                  ? (<Outlet />)
                  : (
                    <div className={styles.update}>
                      <Empty
                        description="數據包更新中，請稍等！"
                      />
                    </div>
                  )
              }
            </Content>
          </Layout>
        </Layout>
        {/* NOTE: Opinion Drawer */}
        {
          userStore.search
          && (
            <Drawer
              className={clsx('opinionDrawer', styles.searchDrawerContainer)}
              bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
              placement="right"
              closable={false}
              onClose={this.viewModel.onDrawerClose}
              open={this.viewModel.isDrawerOpen}
              width={1100}
            >
              <div
                className={clsx('opinionScrollContainer', styles.container)}
              >
                <div className={styles.searchHeader}>
                  <div className={styles.headerItem}>
                    <img src={TitlePrefixDot} alt="" />
                    <div className={styles.searchTitle}>
                      {i18n.t('search_name')}
                    </div>
                    <Tooltip
                      title={i18n.t('search_note_content')}
                      placement="bottomLeft"
                      overlayClassName={styles.tooltip}
                      arrow={{ pointAtCenter: true }}
                    >
                      <img src={NoteIcon} alt="" />
                    </Tooltip>
                  </div>
                  <div className={styles.searchDateContainer}>
                    <div className={styles.searchDateTitle}>
                      {i18n.t('date_type_title')}
                    </div>
                    <SinglePeriodDatePicker
                      viewModel={this.viewModel.datePickerViewModel}
                      callback={this.viewModel.onDateChange}
                    />
                  </div>
                </div>
                <div className={styles.searchSettingContainer}>
                  <div className={styles.inputContainer}>
                    <div className={styles.searchTitle}>
                      {i18n.t('search_setting')}
                    </div>
                    <Tooltip
                      title={i18n.t('search_tooltip_content')}
                      placement="bottomLeft"
                    >
                      <div className={styles.searchContainer}>
                        <input
                          className={styles.searchInput}
                          placeholder={i18n.t('search_name')}
                          value={this.viewModel.keyword}
                          onChange={this.viewModel.onSearchChange}
                          onKeyDown={this.viewModel.onKeyDown}
                        />
                        <div
                          className={styles.searchBtn}
                          onClick={this.viewModel.onSearchSubmit}
                        >
                          <img src={SearchIcon} alt="" />
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                  <div className={styles.filterContainer}>
                    <div className={styles.filterTitleContainer}>
                      <div className={styles.filterTitle}>
                        {i18n.t('search_filter')}
                      </div>
                      <img src={FilterIcon} alt="" className={styles.filterIcon} />
                      <Select
                        placeholder={i18n.t('filter_post_type_all')}
                        containerName="opinionScrollContainer"
                        options={POST_TYPE}
                        value={this.viewModel.filterPost}
                        onSelect={this.viewModel.onPostTypeChange}
                        popupMatchSelectWidth={false}
                        popupClassName={styles.sortMenu}
                        className={clsx('sortSelect', styles.filterSelect)}
                        suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      />
                      <Select
                        placeholder={i18n.t('filter_word_of_mouth_all')}
                        options={WORD_OF_MOUTH_TYPE}
                        value={this.viewModel.filterWordOfMouth}
                        onSelect={this.viewModel.onWOMChange}
                        popupMatchSelectWidth={false}
                        popupClassName={styles.sortMenu}
                        className={clsx('sortSelect', styles.filterSelect)}
                        suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      />
                      <PopoverSelect
                        viewModel={this.viewModel.category}
                        placeholder={i18n.t('source_category_title')}
                      />
                      <PopoverSelect
                        viewModel={this.viewModel.website}
                        placeholder={i18n.t('source_website_title')}
                        disabled={!this.viewModel.category.selected.id}
                      />
                      <PopoverSelect
                        viewModel={this.viewModel.channel}
                        placeholder={i18n.t('source_channel_title')}
                        disabled={!this.viewModel.website.selected.id}
                      />
                      <Button
                        icon={<CheckOutlined />}
                        className={styles.submitBtn}
                        onClick={this.viewModel.onFilterSubmit}
                      >
                        {i18n.t('btn_filter_submit')}
                      </Button>
                      {
                        this.viewModel.isShowCleanAll
                        && (
                          <Button
                            icon={<CloseOutlined />}
                            className={styles.cleanAllBtn}
                            onClick={this.viewModel.cleanAllFilter}
                          >
                            {i18n.t('btn_filter_clean_all')}
                          </Button>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.searchResContainer}>
                  <div className={styles.searchResHeader}>
                    <div
                      className={clsx(styles.searchResTitle, this.viewModel.activeSection === 'topic' && styles.active)}
                      onClick={() => this.viewModel.onSectionChange('topic')}
                    >
                      {i18n.t('search_res_title')}
                    </div>
                    <div
                      className={clsx(styles.searchResTitle, this.viewModel.activeSection === 'chart' && styles.active)}
                      onClick={() => this.viewModel.onSectionChange('chart')}
                    >
                      {i18n.t('search_res_chart')}
                    </div>
                  </div>
                  {
                    this.viewModel.activeSection === 'topic'
                    && (
                      <div>
                        {
                          this.viewModel.resList.length > 0
                            ? (
                              <>
                                <div className={styles.sortContainer}>
                                  <div className={styles.titleAndSortContainer}>
                                    <div className={styles.resTitle}>
                                      {i18n.t('topic_res_count').replace('%count%', this.viewModel.totalCount)}
                                    </div>
                                    <Select
                                      containerName="opinionScrollContainer"
                                      placeholder={i18n.t('sort_type_title')}
                                      value={this.viewModel.sortType}
                                      options={OPINION_SORT_TYPE}
                                      popupMatchSelectWidth={false}
                                      popupClassName={styles.sortMenu}
                                      className={clsx('sortSelect', styles.sortSelect)}
                                      onSelect={this.viewModel.onSortTypeChange}
                                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                                    />
                                  </div>

                                  <Button
                                    icon={<DownloadOutlined />}
                                    className={styles.downloadButton}
                                    onClick={this.viewModel.onDownloadClick}
                                  >
                                    {i18n.t('topic_download')}
                                  </Button>
                                </div>
                                <div className={styles.resContainer}>
                                  {
                                    this.viewModel.resList.map((el, i) => {
                                      return (
                                        <SearchTopicCard key={el.vocKey} order={i} viewModel={el} userType={userStore.activeUserType} />
                                      );
                                    })
                                  }
                                  {
                                    this.viewModel.hasNext
                                    && (
                                      <Waypoint
                                        onEnter={this.viewModel.onScrollEnd}
                                      />
                                    )
                                  }
                                </div>
                              </>
                            )
                            : (
                              <div
                                className={styles.resEmptyContainer}
                              >
                                <Empty
                                  description={this.viewModel.isProcess ? '搜尋中，請稍等' : '找不到話題，請試試其他關鍵字'}
                                />
                              </div>
                            )
                        }
                      </div>
                    )
                  }
                  {
                    this.viewModel.activeSection === 'chart'
                    && (
                      <OpinionChartSection
                        viewModel={this.viewModel.chartViewModel}
                      />
                    )
                  }
                </div>
              </div>
              <div
                className={styles.customizeBack}
                onClick={this.viewModel.onDrawerClose}
              >
                <img src={RightArrowIcon} alt="" />
              </div>
              <ScrollTop
                containerName="opinionScrollContainer"
              />
            </Drawer>
          )
        }
        {
          optionsStore.isLoading
          && (
            <div className={styles.curtain}>
              <div className={styles.loadingContainer}>
                <img src={Loading} alt="" style={{ borderRadius: 8 }} />
                {
                  router.location.pathname !== '/home'
                  && (
                    <>
                      <div className={styles.note}>
                        {'數據讀取中，查詢時間範圍越長，等候時間相對較久。\n建議可以嘗試縮短時間或是篩選條件設定，若欲放棄讀取可返回首頁。'}
                      </div>
                      <Button
                        className={styles.rejectBtn}
                        onClick={() => optionsStore.onReject(router)}
                      >
                        返回首頁
                      </Button>
                    </>
                  )
                }
              </div>
            </div>
          )
        }
      </>
    );
  }
}

CustomizeLayout.propTypes = {
  router: PropTypes.object
};

CustomizeLayout.defaultProps = {
  router: {}
};

export default withRouter(CustomizeLayout);
