/* eslint-disable */
export default {
  "ID": "zh_TW",
  "": "",
  "common_industry": "行業",
  "common_brand": "品牌",
  "common_series": "系列",
  "common_product": "產品",
  "common_account": "帳戶",
  "btn_filter_submit": "套用",
  "btn_filter_clean_all": "清除條件",
  "filter_post_type_all": "主回文",
  "filter_post_type_post": "主文",
  "filter_post_type_comment": "回文",
  "filter_sentiment_title": "情緒類型",
  "filter_sentiment_positive": "偏正面",
  "filter_sentiment_neutral": "偏中性",
  "filter_sentiment_negative": "偏負面",
  "common_sentiment_tooltip": "點擊切換情緒燈號",
  "filter_word_of_mouth_title": "聲量類型",
  "filter_word_of_mouth_all": "全部聲量",
  "filter_word_of_mouth_nature": "自然聲量",
  "filter_word_of_mouth_event": "活動聲量",
  "source_category_title": "來源類型",
  "filter_source_category_forum": "論壇",
  "filter_source_category_fb_page": "FB粉絲團",
  "filter_source_category_fb_group": "FB社團",
  "filter_source_category_news": "新聞",
  "filter_source_category_blog": "部落格",
  "filter_source_category_ig": "Instagram",
  "filter_source_category_threads": "Threads",
  "filter_source_category_youtube": "YouTube",
  "filter_source_category_others": "其他",
  "source_website_title": "來源網站",
  "source_channel_title": "來源頻道",
  "sort_type_title": "排序",
  "sort_type_date": "時間",
  "sort_type_comment_count": "回應數",
  "sort_type_share_count": "分享數",
  "sort_type_view_count": "點閱數",
  "date_type_title": "日期範圍查詢",
  "date_type_seven_days": "最近七天",
  "date_type_fourteen_days": "最近十四天",
  "date_type_thirty_days": "最近三十天",
  "date_type_manual": "自訂",
  "topic_res_count": "顯示共%count%項結果",
  "topic_download": "下載話題列表",
  "common_date_filter": "查詢範圍",
  "common_search_placeholder": "請輸入搜尋關鍵字",
  "home_page_name": "首頁",
  "home_newest_announcement": "最新公告",
  "home_announcement_setting": "公告設定",
  "home_announcement_add": "新增公告",
  "home_announcement_table_is_show": "上架顯示",
  "home_announcement_table_date": "公告日期",
  "home_announcement_table_content": "公告內容",
  "home_announcement_text_placeholder": "請輸入公告內容",
  "home_website_article": "最新熱門文章",
  "home_more_article": "查看更多官網文章",
  "home_source_type_statistics": "來源類型頻道數",
  "home_other_service": "其他服務資源",
  "home_service_setting": "資源設定",
  "home_service_add": "新增資源",
  "home_service_table_date": "日期",
  "home_service_table_name": "資源名稱",
  "home_service_table_name_placeholder": "請輸入資源名稱",
  "home_service_table_url": "影片連結",
  "home_service_table_url_placeholder": "請輸入影片連結",
  "home_table_actions": "操作",
  "home_table_edit": "編輯",
  "home_table_cancel": "取消",
  "home_table_save": "儲存",
  "home_table_delete": "刪除",
  "search_name": "輿情搜尋",
  "search_note_content": "在i-Buzz所有資料庫來源中命中關鍵字的發回文話題列表，預設依照時間排序。",
  "search_tooltip_content": "可使用關鍵字語法，&代表and、+代表or、-代表not，另可使用()指定優先運算，但須注意括弧內+須改為;，語法皆為半型符號，如範例：小棕瓶+(ESTEE LAUDER;雅詩;EL)&(超導修護露;全方位修護露)-面膜-安瓶",
  "search_setting": "搜尋設定",
  "search_filter": "篩選統計",
  "search_res_title": "熱門話題列表",
  "search_res_chart": "圖表摘要",
  "topic_card_source": "來源網站/頻道",
  "topic_card_author": "發文ID",
  "topic_card_date": "發文日期",
  "topic_card_view_count": "點閱",
  "topic_card_share_count": "分享",
  "topic_card_comment_count": "最新總回文",
  "topic_card_comment_count_unit": "則",
  "topic_card_view_cache_page": "查看庫存頁",
  "opinion_chart_summary_title": "總覽",
  "opinion_chart_summary_keyword": "關鍵字",
  "opinion_chart_summary_volume": "總聲量",
  "opinion_chart_summary_sentiment": "情緒好感度",
  "opinion_chart_summary_category": "最熱門來源類型",
  "opinion_chart_summary_website": "最熱門來源網站",
  "opinion_chart_summary_channel": "最熱門來源頻道",
  "opinion_chart_summary_term": "最熱門字詞",
  "opinion_chart_summary_topic": "最熱門話題",
  "opinion_chart_summary_empty": "暫無資料",
  "opinion_chart_download_all_data": "下載全部數據",
  "opinion_chart_download_all_chart": "下載全部圖表",
  "opinion_chart_download_data": "下載數據",
  "opinion_chart_download_chart": "下載圖檔",
  "opinion_chart_collapse_chart": "收合/展開圖表",
  "opinion_chart_day_trend": "日聲量趨勢",
  "opinion_chart_month_trend": "月聲量柱狀",
  "opinion_chart_category_proportion": "來源類型佔比",
  "opinion_chart_channel_ranking": "來源頻道排行",
  "opinion_chart_sentiment": "情緒總覽",
  "opinion_chart_keyword": "熱詞文字雲",
  "opinion_chart_data_list": "數據列表",
  "opinion_chart_block_word": "屏蔽詞",
  "topic_page_name": "話題列表",
  "topic_levels_title": "話題列表條件",
  "topic_levels_setting": "條件設定",
  "topic_source_all": "總覽",
  "topic_word_of_mouth_title": "聲量",
  "topic_article_url": "原文URL",
  "topic_common_hit_brand": "共同提及品牌",
  "topic_common_hit_seres": "共同提及系列",
  "topic_common_hit_product": "共同提及產品",
  "topic_comments_total_count": "聲量回文",
  "topic_comment_table_sentiment": "情緒",
  "topic_comment_table_number": "編號",
  "topic_comment_table_author": "回文作者",
  "topic_comment_table_content": "回文內容",
  "topic_comment_table_date": "回文時間",
  "topic_comment_change_sentiment_title": "修改情緒為：",
  "topic_comment_change_sentiment_placeholder": "選擇情緒",
  "chart_page_name": "圖表分析",
  "chart_page_statistic_filter": "統計篩選",
  "chart_page_graphic_filter": "圖表篩選",
  "chart_page_index_title": "指標",
  "chart_page_index_frequency": "詞頻",
  "chart_page_index_weighted": "權重",
  "chart_page_word_count_title": "顯示熱詞數量",
  "chart_page_word_count_twenty": "20",
  "chart_page_word_count_fifty": "50",
  "chart_page_word_count_hundred": "100",
  "chart_page_date_range": "日期範圍",
  "wordbook_page_name": "分類詞庫",
  "wordbook_page_title": "分類詞庫圖表",
  "wordbook_page_block_modal_title": "查看屏蔽名單",
  "wordbook_page_table_name": "屏蔽名單名稱",
  "wordbook_page_table_action_title": "動作",
  "wordbook_page_table_action_recover": "復原",
  "wordbook_page_chart_radar": "雷達圖",
  "wordbook_page_chart_words": "文字雲",
  "wordbook_page_hover_prefix": "提及則數",
  "wordbook_page_statistic_table_title": "顯示數據列表",
  "wordbook_page_table_ranking": "排行",
  "wordbook_page_table_hotword_title": "熱詞詞彙",
  "wordbook_page_table_hotword_frequency_title": "本期提及則數",
  "wordbook_page_table_hotword_prev_frequency_title": "前期提及則數",
  "wordbook_page_modal_content": "已點擊「%word%」，請選擇顯示內容",
  "wordbook_page_modal_action_topic": "檢視話題列表",
  "wordbook_page_modal_action_further": "下探文字雲",
  "wordbook_page_modal_action_block": "屏蔽熱詞",
  "data_report_page_name": "數據報告",
  "data_report_type_industry_trend": "行業趨勢觀察",
  "data_report_type_market_differentiation": "市場差異分析",
  "data_report_type_brand_competition": "品牌競爭分析",
  "data_report_type_brand_health": "品牌健檢分析",
  "data_report_type_brand_effect": "品牌成效分析",
  "data_report_type_brand_routine": "品牌例行性報告",
  "data_report_setting_text": "條件設定",
  "data_report_search": "查詢",
  "data_report_clean": "清除條件",
  "data_report_select_series": "選擇系列群組",
  "data_report_quick_anchor": "快速跳至圖表",
  "data_report_quick_anchor_select_title": "搜尋或選擇圖表名稱",
  "data_report_download_all": "下載全部",
  "data_report_main_brand_title": "選擇主品牌",
  "advance_page_name": "進階分析",
  "advance_type_marketing_7p": "行銷7P分析",
  "advance_type_mind_share": "品牌心佔率",
  "advance_type_star_product": "明星商品佔比",
  "advance_type_reputation": "聲譽定位分析",
  "advance_setting": "條件設定",
  "advance_date_setting": "查詢範圍",
  "advance_analysis_item_title": "分析項目",
  "advance_analysis_brand_item_title": "品牌項目",
  "advance_analysis_source_title": "來源範圍",
  "advance_analysis_source_item_title": "來源項目",
  "advance_item_no_data": "目前無資料，請點選%type%進行設定。",
  "advance_vom_type": "聲量類型",
  "advance_vom_type_placeholder": "請選擇聲量類型",
  "advance_feature_set": "行銷7P詞庫",
  "advance_feature_set_placeholder": "請選擇特性關鍵字",
  "advance_setting_res_title": "資料範圍",
  "related_page_name": "關聯分析",
  "advance_search_page_name": "交叉分析",
  "advance_search_time_setting": "設定時間範圍",
  "advance_search_suggestions": "建議交叉項目",
  "advance_search_brand_series": "品牌x系列",
  "advance_search_brand_product": "品牌x產品",
  "advance_search_brand_forum_channel": "品牌x論壇來源頻道",
  "advance_search_brand_fb_channel": "品牌xFB來源頻道",
  "advance_search_suggest_note": "點擊建議交叉項目，系統將會自動帶該專案的參數，再點擊送出即可查看結果。",
  "advance_search_cross_setting": "交叉條件設定",
  "advance_search_cross_setting_option_one": "選擇條件一",
  "advance_search_cross_setting_option_two": "選擇條件二",
  "advance_search_cross_setting_add_params": "新增參數",
  "advance_search_cross_setting_clean_setting": "清除條件設定",
  "advance_search_cross_setting_submit": "送出",
  "advance_search_sentiment_title": "選擇情緒",
  "advance_search_feature_title": "選擇分類詞庫",
  "bulletin_page_name": "系統通報",
  "bulletin_add_btn": "通報主題",
  "bulletin_search_placeholder": "搜尋通報主旨",
  "bulletin_total_count": "共%count%項",
  "bulletin_table_name_title": "通報主旨",
  "bulletin_table_time_title": "通報寄送時間範圍",
  "bulletin_table_frequency_title": "通報週期",
  "bulletin_table_action_title": "動作",
  "bulletin_delete_alert_content": "確認是否刪除{%name%}？\n注意：將會永久刪除與停止寄送此通報的所有資料",
  "bulletin_delete_alert_confirm": "確認",
  "bulletin_delete_alert_cancel": "取消",
  "bulletin_drawer_title": "新增通報主題",
  "bulletin_drawer_edit_title": "編輯通報",
  "bulletin_drawer_name_title": "*通報主旨",
  "bulletin_drawer_name_placeholder": "請輸入通報主旨(至多60字元)",
  "bulletin_drawer_name_error": "請輸入通報主旨",
  "bulletin_drawer_time_title": "*寄送時間起始範圍",
  "bulletin_drawer_time_placeholder": "選擇日期",
  "bulletin_drawer_time_toottip": "時間最大範圍為數據包時間範圍",
  "bulletin_drawer_time_error": "請選擇通報區間",
  "bulletin_drawer_type_title": "*通報方式",
  "bulletin_drawer_type_email": "電子報",
  "bulletin_drawer_AI_title": "*AI 自動生成摘要",
  "bulletin_drawer_count_title": "*電子報-話題顯示數量",
  "bulletin_drawer_count_placeholder": "選擇話題數量",
  "bulletin_option_count_ten": "十則",
  "bulletin_option_count_twenty": "二十則",
  "bulletin_option_count_fifty": "五十則",
  "bulletin_option_count_hundred": "一百則",
  "bulletin_option_count_error": "請選擇話題數量",
  "bulletin_drawer_frequency_title": "*電子報-通報週期",
  "bulletin_drawer_frequency_placeholder": "選擇通報週期",
  "bulletin_drawer_frequency_daily": "日報",
  "bulletin_drawer_frequency_weekly": "週報",
  "bulletin_drawer_frequency_monthly": "月報",
  "bulletin_drawer_frequency_today": "當日報",
  "bulletin_drawer_frequency_today_noon_simple": "當日午報",
  "bulletin_drawer_frequency_today_night_simple": "當日晚報",
  "bulletin_drawer_frequency_error": "請選擇通報週期",
  "bulletin_drawer_frequency_today_desc": "當日報包含",
  "bulletin_drawer_frequency_today_noon": "當日12pm 寄送午報",
  "bulletin_drawer_frequency_today_night": "當日 6pm 寄送晚報",
  "bulletin_drawer_receiver_section_title": "收件人設定",
  "bulletin_drawer_receiver_new_title": "新增電子報收件人信箱",
  "bulletin_drawer_receiver_new_placeholder": "輸入收件人信箱",
  "bulletin_drawer_receiver_btn": "新增",
  "bulletin_drawer_receiver_title": "收件人信箱",
  "bulletin_drawer_receiver_placeholder": "尚未建立收件人",
  "bulletin_drawer_levels_title": "設定階層項目",
  "bulletin_drawer_sources_title": "設定來源項目",
  "bulletin_drawer_sources_header": "來源資料項目設定",
  "bulletin_drawer_sentiment_title": "設定情緒項目",
  "bulletin_drawer_sentiment_header": "情緒資料項目設定",
  "bulletin_drawer_sentiment_option": "選擇情緒",
  "bulletin_drawer_selected_title": "摘要",
  "bulletin_drawer_selected_placeholder": "目前無資料",
  "bulletin_drawer_levels": "階層項目",
  "bulletin_drawer_sources": "來源項目",
  "bulletin_drawer_sentiment": "情緒項目",
  "bulletin_drawer_add_new_content": "通報內容",
  "bulletin_drawer_confirm": "儲存設定",
  "bulletin_drawer_send_immediately": "立即發送",
  "bulletin_drawer_send_immediately_disabled_noon": "當日午報需於當日12:00後方能寄送",
  "bulletin_drawer_send_immediately_disabled_night": "當日晚報需於當日18:00後方能寄送",
  "packages_page_name": "數據包管理",
  "packages_button_name": "管理數據包",
  "packages_filter_date_title": "建立日期篩選",
  "packages_filter_delete_date_title": "異動日期篩選",
  "packages_add_title": "建立數據包",
  "packages_delete_title": "數據包刪除記錄",
  "packages_sort_pin_title": "以置頂順序排列",
  "packages_search_placeholder": "搜尋數據包名稱",
  "packages_table_package_name": "VOC+數據包名稱",
  "packages_table_package_holder": "數據包管理者",
  "packages_table_package_users": "數據包分享給所屬客戶\n（我的客戶名單）",
  "packages_table_establish_time": "建立時間",
  "packages_table_adjust_time": "最後異動時間",
  "packages_table_action": "動作",
  "packages_table_action_edit": "編輯數據包設定",
  "packages_table_action_pin": "置頂此數據包",
  "packages_table_action_check_project": "查看「專案管理工具」連結設定",
  "packages_table_action_delete": "刪除數據包",
  "packages_table_action_check_package": "查看數據包設定",
  "packages_table_row_action_delete": "刪除",
  "packages_table_row_action_cancel": "取消選取",
  "packages_drawer_title": "建立數據包設定",
  "packages_drawer_holder": "數據包擁有者",
  "packages_drawer_holder_placeholder": "請選擇數據包擁有者",
  "packages_drawer_name": "*數據包名稱",
  "packages_drawer_nam_placeholder": "請輸入數據包名稱",
  "packages_drawer_select_project": "*選擇專案",
  "packages_drawer_select_dep_placeholder": "選擇部門",
  "packages_drawer_select_sales_placeholder": "選擇業務",
  "packages_drawer_select_project_placeholder": "請輸入專案名稱或 ID",
  "packages_drawer_valid_time": "*設定時間範圍",
  "packages_drawer_levels_checkbox": "*開放系統功能對應階層",
  "packages_drawer_level_data": "*階層資料顯示項目",
  "packages_drawer_series": "系列檔案",
  "packages_drawer_series_placeholder": "選擇標籤群組",
  "packages_drawer_select_level_btn": "階層資料項目設定",
  "packages_drawer_wordbook_title": "分類詞庫檔案",
  "packages_drawer_wordbook_placeholder": "選擇特性關鍵字檔案",
  "packages_drawer_share_to_title": "數據包分享給",
  "packages_drawer_share_to_placeholder": "請選擇客戶",
  "package_create_submit": "設定完成並建立數據包",
  "package_edit_submit": "設定完成並更新數據包",
  "package_modal_title": "階層資料項目設定",
  "package_modal_brand_select": "選擇品牌",
  "package_modal_series_select": "選擇系列",
  "package_modal_product_select": "選擇產品",
  "package_modal_select_all": "全選",
  "package_modal_input_placeholder": "請輸入搜尋關鍵字",
  "package_modal_clean": "清除設定條件",
  "package_modal_apply": "套用",
  "package_modal_close": "關閉",
  "packages_back_home": "回到首頁",
  "packages_back_manage": "回數據包列表",
  "account_validity": "帳戶使用起訖時間",
  "account_change_password": "修改密碼",
  "account_logout": "登出",
  "account_manage_page_name": "帳號管理",
  "account_manage_page_title": "VOC+帳號管理／帳號總覽",
  "user_sso_identity_title": "SSO身份",
  "user_sso_identity_employee": "內部人員",
  "user_sso_identity_customer": "客戶",
  "user_sso_status_title": "SSO帳號狀態",
  "user_sso_status_active": "已啟用",
  "user_sso_status_inactive": "已停用",
  "filter_sso_status": "SSO狀態",
  "filter_department_employee": "部門(內部)",
  "filter_department_customer": "部門(客戶)",
  "account_manage_column_title_account_name": "帳號名稱",
  "account_manage_column_title_account": "帳號",
  "account_manage_column_title_dep_employee": "部門\n(內部人員用)",
  "account_manage_column_title_dep_customer": "部門\n(客戶專用)",
  "account_manage_column_title_voc_identity": "帳號角色",
  "account_manage_column_title_salesman": "指定業務",
  "account_manage_column_title_last_adjust_time": "最後更新異動時間",
  "account_manage_column_title_aciton": "動作",
  "account_manage_column_action_note": "帳戶權限與功能設定",
  "user_voc_login_auth_title": "VOC+登入權限",
  "user_voc_login_auth_allow": "可登入",
  "user_voc_login_auth_disallow": "不可登入",
  "account_manage_setting_voc_identity": "*VOC+帳號角色",
  "account_manage_voc_identity_admin": "Admin",
  "account_manage_voc_identity_leader": "組長",
  "account_manage_voc_identity_member": "組員",
  "account_manage_voc_identity_customer": "客戶",
  "account_manage_add_customer": "新增客戶帳號",
  "account_manage_search_title": "搜尋帳號名稱或帳號",
  "account_manage_basic_setting_title": "帳戶基本設定",
  "account_manage_auth_setting_title": "開放功能設定",
  "account_manage_setting_identity": "身份",
  "account_manage_setting_department": "所屬部門",
  "account_manage_setting_user_name": "使用者姓名",
  "account_manage_setting_user_account": "帳號",
  "account_manage_setting_voc_login_auth": "*VOC+登入權限",
  "account_manage_setting_salesman": "*指定業務",
  "account_manage_setting_dep_placeholder": "請選擇部門",
  "account_manage_setting_salesman_placeholder": "請選擇業務",
  "account_manage_setting_account_valid": "*帳戶使用起訖時間",
  "account_manage_setting_account_valid_placeholder": "選擇起訖",
  "account_manage_setting_open_packages": "*開放數據包數量",
  "account_manage_setting_packages_placeholder": "輸入數據包數量",
  "account_manage_create_modal_title": "新增客戶帳號",
  "account_manage_create_modal_identity_title": "身份",
  "account_manage_create_modal_identity_placeholder": "請選擇客戶身份",
  "account_manage_create_modal_department_title": "所屬部門(客戶專用)",
  "account_manage_create_modal_department_placeholder": "請選擇所屬部門",
  "account_manage_create_modal_name_title": "使用者姓名",
  "account_manage_create_modal_name_placeholder": "請輸入使用者姓名",
  "account_manage_create_modal_account_title": "帳號",
  "account_manage_create_modal_account_placeholder": "請輸入電子信箱",
  "account_manage_create_modal_password_title": "密碼",
  "account_manage_create_modal_password_placeholder": "請輸入密碼",
  "account_manage_create_modal_password_confirm_title": "重新輸入密碼",
  "account_manage_create_modal_password_confirm_placeholder": "請重新輸入密碼",
  "account_manage_create_modal_submit": "建立帳號並設定"
};
