import React from 'react';
import { makeObservable, observable, action, computed, runInAction, reaction } from 'mobx';
import dayjs from 'dayjs';
import { message } from 'antd';
import * as xlsx from 'xlsx';
import html2canvas from 'html2canvas';

import PackageService from 'src/services/package';
import packageStore from 'src/stores/packageStore';
import userStore from 'src/stores/userStore';
import optionsStore from 'src/stores/optionsStore';

import SourceService from 'src/services/source';
import AdvanceAnalysisService from 'src/services/advanceAnalysis';
import BlockedWordsService from 'src/services/blockedwords';

import SourceSelectModalViewModel from 'src/components/SourceSelectModal/viewModel';
import CategoryPopoverSelectViewModel from 'src/components/CategoryPopoverSelect/viewModel';
import MultiPeriodDatePickerViewModel from 'src/components/MultiPeriodDatePicker/viewModel';

import SingleClassModalViewModel from '../components/SingleClassModal/viewModel';
import ChartItemViewModel from '../components/ChartItem/viewModel';
import WordChartItemViewModel from '../components/WordChartItem/viewModel';
import CheckItemViewModel from '../components/SingleClassModal/CheckItem/viewModel';

export default class Marketing7PageViewModel {
  @observable selectedSec = 'setting';
  @observable date = [dayjs(), dayjs()];

  @observable selectedWOM = null;

  @observable featureSets = [];
  @observable selectedFeatureSets = null;

  @observable brandSelectViewModel = new SingleClassModalViewModel();
  @observable sourceSelectModalViewModel = new SourceSelectModalViewModel();
  @observable categoryPopover = new CategoryPopoverSelectViewModel();

  @observable brandCache = [];
  @observable channelCache = [];

  @observable selectedChart = 'radar';
  @observable radarChart = {};
  @observable wordChart = {};

  @observable selectedIndex = 'frequency';
  @observable selectedCount = 20;

  @observable selectedPostType = 'all';
  @observable selectedSourceCategoryType = null;

  @observable blockWords = [];
  @observable cacheBlockWords = [];
  @observable isStopModalOpen = false;

  @observable status = {};
  @observable jobId = '';

  @observable multipleDatePickerViewModel = new MultiPeriodDatePickerViewModel('range');

  @observable isDownload = false;
  @observable downloadList = [];
  @observable downloadFileName = '';

  @observable once = null;

  @observable cacheContent = {
    date: [dayjs(), dayjs()],
    brand: '',
    source: '',
    sourceTotal: '',
    vom: ''
  };

  @observable reserveFeatureId = '';

  @computed get selectedBrand() {
    return this.brandSelectViewModel.items.filter((el) => el.checked).map((el) => el.id);
  }

  @computed get sourceSelectedIncomplete() {
    return this.sourceSelectModalViewModel.selectedChannels.length === 0;
  }

  @computed get dateString() {
    return `${this.date[0].format('YYYY-MM-DD')} - ${this.date[1].format('YYYY-MM-DD')}`;
  }

  @computed get isShowStatisticClean() {
    return this.selectedPostType !== 'all' || this.categoryPopover.selected.length > 0;
  }

  @computed get isShowChartClean() {
    return this.selectedIndex !== 'frequency' || this.selectedCount !== 20;
  }

  @computed get totalChannels() {
    let count = 0;
    this.sourceSelectModalViewModel.selectedChannelsByCategory.forEach((el) => {
      count += el.count;
    });
    return count;
  }

  @computed get disabledAdjustSetting() {
    return !['DONE', 'CANCEL', 'FAIL'].includes(this.status.stage) && this.jobId;
  }

  @computed get progressText() {
    switch (this.status.stage) {
      case 'DONE':
        return '分析成功';
      case 'FAIL':
        return '分析失敗';
      case 'CANCEL':
        return '取消分析';
      default:
        return `請稍候，模組分析中...(${this.status.progress}%)`;
    }
  }

  // > for further analysis
  // @computed get selectedChannel() {
  //   const result = [];
  //   for (let i = 0; i < this.sourceSelectModalViewModel.channel.length; i += 1) {
  //     for (let j = 0; j < this.sourceSelectModalViewModel.channel[i].children.length; j += 1) {
  //       if (this.sourceSelectModalViewModel.channel[i].children[j].checked) {
  //         result.push({
  //           id: this.sourceSelectModalViewModel.channel[i].children[j].id,
  //           name: this.sourceSelectModalViewModel.channel[i].children[j].name
  //         });
  //       }
  //     }
  //   }
  //   return result;
  // }

  constructor() {
    makeObservable(this);

    this.setReaction();
  }

  @action setReaction = async () => {
    if (!packageStore.isLevelProcessing) {
      await this.init();
    } else {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        async (bool) => {
          if (!bool) {
            await this.init();
            once();
          }
        }
      );
    }


  };

  @action didMount = () => {
    const onceReaction = reaction(
      () => this.selectedSec,
      async (section) => {
        if (section === 'result') {
          this.getGraphicData();
        }
      }
    );

    this.once = onceReaction;
  };

  @action init = async () => {
    await this.getInfo();
    await this.getFeatureSet();
    await this.getBlockedWords();
    await this.updateSection();
  };

  @action updateSection = async () => {
    if (userStore.activeUserType === 'customer') {
      this.selectedSec = 'result';
      await this.getGraphicData();
    }
  };

  @action getInfo = async () => {
    optionsStore.setLoading('7p');
    try {
      const res = await AdvanceAnalysisService.getMarketing7PSetting();
      const { marketing7PStatus } = await PackageService.getAnalysisLog(packageStore.activePackageId);

      runInAction(() => {
        const {
          date,
          selectedBrand,
          selectedChannel,
          selectedFeatureSet,
          womType
        } = res;

        const {
          jobId
        } = marketing7PStatus;

        const endDateValue = Math.min(dayjs(packageStore.packageEndDate).valueOf(), dayjs().valueOf());
        this.date = jobId ? date : [dayjs(endDateValue).subtract(7, 'day'), dayjs(endDateValue)];
        this.selectedWOM = womType;
        this.selectedFeatureSets = selectedFeatureSet;
        this.brandCache = selectedBrand;
        this.channelCache = selectedChannel;
        this.jobId = jobId;
        this.status = marketing7PStatus;
        this.multipleDatePickerViewModel.updateDisabledDateRange(this.date);
      });

      this.setBrand();
      await this.setSource();
      runInAction(() => {
        this.sourceSelectModalViewModel.setIsShow('channel');
        this.sourceSelectModalViewModel.setInit(this.channelCache);
        this.cacheContent = {
          date: this.date,
          dateString: this.dateString,
          brand: this.brandSelectViewModel.text,
          source: this.sourceSelectModalViewModel.selectedChannelsByCategory.map((el) => ({ id: el.id, name: el.name, count: el.count })),
          sourceTotal: this.totalChannels,
          vom: this.selectedWOM
        };
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('7p');
    }
  };

  @action getFeatureSet = async () => {
    if (userStore.activeUserType === 'customer') {
      return;
    }
    optionsStore.setLoading('feature');
    try {
      const { featureSetList } = await PackageService.getFeatureSet(packageStore.activeProjectId);

      runInAction(() => {
        this.featureSets = [...featureSetList];
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('feature');
    }
  };

  @action onSecChange = (e) => {
    this.selectedSec = e;
  };

  @action onDateSelect = (e) => {
    this.date = e;
  };

  @action onVOMSelect = (e) => {
    this.selectedWOM = e;
  };

  @action onFeatureSetSelect = (e) => {
    this.selectedFeatureSets = e;
  };

  @action onBrandSelectButtonClick = () => {
    this.brandSelectViewModel.onModalOpen();
  };

  @action setBrand = () => {
    if (packageStore.brand.length > 0) {
      const brand = packageStore.brand.map((el) => new CheckItemViewModel(el));
      this.brandSelectViewModel.setItems('brand', brand);
      this.brandSelectViewModel.items.forEach((b) => {
        if (this.brandCache.includes(b.id)) {
          b.setInit();
        }
      });
    } else {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        (bool) => {
          if (!bool) {
            const brand = packageStore.brand.map((el) => new CheckItemViewModel(el));
            this.brandSelectViewModel.setItems('brand', brand);
            this.brandSelectViewModel.items.forEach((b) => {
              if (this.brandCache.includes(b.id)) {
                b.setInit();
              }
            });
            once();
          }
        }
      );
    }
  };

  @action onSourceSelectButtonClick = () => {
    this.sourceSelectModalViewModel.onOpenWithType('channel');
  };

  @action setSource = async () => {
    try {
      const { source } = await SourceService.getPackageSources();

      runInAction(() => {
        this.sourceSelectModalViewModel.updateSources(source);
        this.categoryPopover.updateList(source);
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onClean = () => {
    this.date = [];
    this.selectedWOM = null;
    this.selectedFeatureSets = null;
    this.brandSelectViewModel.onCleanAll();
    this.sourceSelectModalViewModel.onCleanAll();
    this.sourceSelectModalViewModel.onSubmit();
  };

  @action onSubmit = async () => {
    try {
      const { packageStatus, marketing7PStatus } = await PackageService.getAnalysisLog(packageStore.activePackageId);
      if (
        !((!packageStatus.jobId || ['DONE', 'CANCEL', 'FAIL'].includes(packageStatus.stage))
          && (!marketing7PStatus.jobId || ['DONE', 'CANCEL', 'FAIL'].includes(marketing7PStatus.stage)))
      ) {
        message.error('數據包/進階分析執行中，無法編輯進階分析設定');
        return;
      }

      const data = {
        startDate: this.date[0].startOf('day').toISOString(),
        endDate: this.date[1].endOf('day').toISOString(),
        searchKeywordLevel1Ids: this.brandSelectViewModel.selectedItems,
        channelIds: this.sourceSelectModalViewModel.selectedChannels,
        womType: this.selectedWOM,
        featureSetId: this.selectedFeatureSets
      };

      const jobId = await AdvanceAnalysisService.postMarketing7PSetting(data);
      runInAction(() => {
        this.jobId = jobId;
        this.status.stage = 'WAITING';
        this.status.progress = 0;
        this.cacheContent = {
          date: this.date,
          dateString: this.dateString,
          brand: this.brandSelectViewModel.text,
          source: this.sourceSelectModalViewModel.selectedChannelsByCategory.map((el) => ({ id: el.id, name: el.name, count: el.count })),
          sourceTotal: this.totalChannels,
          vom: this.selectedWOM
        };
      });
    } catch (error) {
      message.error('設定失敗，請重新確認設定內容');
    }
  };


  // > chart related
  @action onChartChange = (e) => {
    this.selectedChart = e;

    this.getGraphicData();
  };

  @action onChartIndexChange = (e) => {
    this.selectedIndex = e;
  };

  @action onChartCountChange = (e) => {
    this.selectedCount = e;
  };

  @action onCleanChartFilter = () => {
    this.selectedIndex = 'frequency';
    this.selectedCount = 20;
  };

  @action onPostTypeChange = (e) => {
    this.selectedPostType = e;
  };

  @action onSourceCategoryChange = (e) => {
    this.selectedSourceCategoryType = e;
  };

  @action onCleanStatisticFilter = () => {
    this.selectedPostType = 'all';
    this.selectedWOMType = null;
    this.categoryPopover.onCleanAll();
  };

  // > preserve
  @action setReserveFeatureId = (id) => {
    this.reserveFeatureId = id;
  };

  @action onGetChartSubmit = () => {
    if (this.selectedChart !== 'word') {
      return;
    }
    this.setReserveFeatureId(this.wordChart.activeTabId);
  };


  @action getGraphicData = async () => {
    optionsStore.setLoading('graph');
    try {
      if (this.selectedChart === 'radar') {
        const { data } = await AdvanceAnalysisService.getMarketing7pCount({
          date: {
            gte: this.multipleDatePickerViewModel.currentDate[0].format('YYYY-MM-DD'),
            lte: this.multipleDatePickerViewModel.currentDate[1].format('YYYY-MM-DD')
          },
          ...(this.selectedWOM && this.selectedWOM !== 'all' && { womType: this.selectedWOM }),
          ...(this.selectedPostType !== 'all' && { postType: this.selectedPostType }),
          ...(this.categoryPopover.selected.length > 0 && { category: this.categoryPopover.selected.map((el) => el.id) })
        });
        runInAction(() => {
          this.radarChart = new ChartItemViewModel(data, this);
        });
      } else {
        const { data } = await AdvanceAnalysisService.getMarket7pWord({
          date: {
            gte: this.multipleDatePickerViewModel.currentDate[0].format('YYYY-MM-DD'),
            lte: this.multipleDatePickerViewModel.currentDate[1].format('YYYY-MM-DD')
          },
          metric: this.selectedIndex,
          size: this.selectedCount,
          ...(this.selectedWOM && this.selectedWOM !== 'all' && { womType: this.selectedWOM }),
          ...(this.selectedPostType !== 'all' && { postType: this.selectedPostType }),
          ...(this.categoryPopover.selected.length > 0 && { category: this.categoryPopover.selected.map((el) => el.id) }),
          ...(this.blockWords.length > 0 && {
            disableTerm: this.blockWords.map((el) => el.name)
          })
        });
        runInAction(() => {
          this.wordChart = new WordChartItemViewModel(data, this, this.reserveFeatureId);
        });
      }
    } catch (error) {
      message.error('取得圖表異常，請稍後再試');
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('graph');
      });
    }
  };

  @action onDownloadCsv = () => {
    this.isDownload = true;
    const { csv, csvKey } = this.selectedChart === 'radar' ? this.radarChart.data[0].chart[0] : this.wordChart.showChart[0];
    if (!csv || !csvKey) {
      message.info('聲量數據不存在，無法下載檔案');
      return;
    }

    const data = [...csv];
    const header = csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, this.selectedChart === 'radar' ? '雷達圖' : '文字雲');
    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-行銷7P/${this.selectedChart === 'radar' ? '雷達圖' : '文字雲'}-${this.multipleDatePickerViewModel.currentTimeText}.xlsx`);
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('.download-chart');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-行銷7P/${this.selectedChart === 'radar' ? '雷達圖' : '文字雲'}-${this.multipleDatePickerViewModel.currentTimeText}}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };

  @action onStopModalChange = (bool) => {
    this.isStopModalOpen = bool;
  };

  @action onStopModalOpen = async () => {
    await this.getBlockedWords();
    this.onStopModalChange(true);
  };

  @action onStopModalClose = () => {
    this.onStopModalChange(false);

    if (this.cacheBlockWords.length !== this.blockWords.length) {
      this.getGraphicData();
    }
  };


  @action getBlockedWords = async () => {
    try {
      const { list } = await BlockedWordsService.getBlockedWords();
      runInAction(() => {
        this.blockWords = list;
        this.cacheBlockWords = list;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onRemoveWord = async (id) => {
    try {
      await BlockedWordsService.removeBlockedWords(id);
      runInAction(() => {
        this.blockWords = this.blockWords.filter((el) => el.id !== id);
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action stopAnalysis = async () => {
    try {
      await AdvanceAnalysisService.stopAnalysis(this.jobId);
      runInAction(() => {
        this.status.stage = 'CANCEL';
      });
    } catch (error) {
      console.log(error);
    }
  };
}
