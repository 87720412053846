import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { decode } from 'js-base64';
import * as xlsx from 'xlsx';
import { POST_TYPE_TC, E_WOM_TYPE, TOPICS_CSV_TITLE_ROW } from 'src/consts';
import TopicService from 'src/services/topic';
import UserService from 'src/services/user';
import ListTopicCardViewModel from 'src/components/ListTopicCard/viewModel';
import DownloadService from 'src/services/download';

export default class TopicsResultPageViewModel {
  @observable package = {
    id: '',
    name: ''
  };
  @observable chartName = '';

  // > normal content
  @observable gte = '';
  @observable lte = '';
  @observable postType = 'all';
  @observable womType = 'all';

  @observable womCount = 0;

  // > source related
  @observable category = [];
  @observable website = [];
  @observable channel = [];

  @observable SNType = null;

  // > levels related
  @observable industry = false;
  @observable brand = [];
  @observable series = [];
  @observable product = [];

  // > keyword chart.
  @observable term = {
    name: '',
    score: 0
  };

  // > features & feature keyword
  @observable featureCategory = {};
  @observable feature = {};

  // > mind-share
  @observable mindShare = {
    mentionRatio: null,
    supportRatio: null
  };

  // > select related
  @observable sortType = 'womCount';
  @observable sentimentType = [-1, 0, 1];

  // > for single sentiment chart use, not allow to adjust sentimentType
  @observable disabledSentiment = false;

  // > res related
  @observable searchId = '';
  @observable topicList = [];
  @observable totalCount = 0;
  @observable postCount = 0;
  @observable commentCount = 0;
  @observable activePage = 1;

  @observable isLoading = false;

  @observable isDownload = false;
  @observable downloadList = [];
  @observable downloadFileName = '';

  // > 241205 -> should loading user profile
  @observable userType = 'customer';

  @computed get totalPage() {
    if (this.postType === 'post') {
      return Math.ceil(this.postCount / 50);
    }

    if (this.postType === 'comment') {
      return Math.ceil(this.commentCount / 50);
    }

    return Math.ceil(this.totalCount / 50);
  }

  @computed get completedDateString() {
    return `日期：${this.gte} ~ ${this.lte}`;
  }

  @computed get hasLevel() {
    return this.brand.length >= 1 || this.product.length >= 1 || this.series.length >= 1;
  }

  @computed get typeText() {
    return `文章類型：${POST_TYPE_TC[this.postType]} | 聲量類型：${E_WOM_TYPE[this.womType]}`;
  }

  constructor() {
    makeObservable(this);
  }

  @action didMount = async () => {
    this.deserialize();

    await this.getUserType();
    await this.getTopics();
  };

  // > 241205 -> should loading user profile
  @action getUserType = async () => {
    try {
      const userInfo = await UserService.myProfile();
      this.userType = userInfo.permission;
    } catch (error) {
      console.log(error);
    }
  };

  @action deserialize = async () => {
    const params = new URL(window.location).searchParams;
    const data = params.get('result').trim();
    const decodeItem = decode(data.trim());
    const result = JSON.parse(decodeItem);

    const {
      packageContent,
      chart,
      brand,
      series,
      product,
      date,
      postType,
      womType,
      category,
      website,
      channel,
      industry,
      term,
      sentiment,
      womCount, // > 聲量
      feature, // > 特性詞文字雲使用
      featureCategory, // > 特性分類
      mindShare, // > 進階分析 - 品牌心佔率
      SNType // > 社群活躍度SN type
    } = result;

    this.package = packageContent;
    this.chartName = chart.name;
    this.gte = date.gte;
    this.lte = date.lte;
    this.brand = brand ?? [];
    this.series = series?.map((el) => ({
      keyId: el.tagKey,
      kayName: el.tagKeyName,
      valueId: el.id,
      name: el.name
    })) ?? [];
    this.product = product ?? [];
    this.postType = postType ?? 'all';
    this.womType = womType ?? 'all';
    this.category = category ?? [];
    this.website = website ?? [];
    this.channel = channel ?? [];
    this.industry = industry;
    this.term = term ?? {};
    this.sentimentType = sentiment && sentiment?.[0]?.id ? sentiment.map((s) => Number(s.id)) : sentiment ?? [-1, 0, 1];
    this.disabledSentiment = !!sentiment;
    this.feature = feature ?? {};
    this.featureCategory = featureCategory && featureCategory?.[0]?.id ? featureCategory[0] : featureCategory ?? {};
    this.mindShare = mindShare ?? {};
    this.SNType = SNType ?? null;

    // > new add here
    this.womCount = womCount;
  };

  @action onSortTypeChange = (e) => {
    this.sortType = e;

    this.topicList = [];

    this.getTopics();
  };

  @action onSentimentTypeChange = (e) => {
    if (e.length === 0) {
      return;
    }
    this.sentimentType = e;
  };

  @action onSentimentChangeComplete = () => {
    this.topicList = [];

    this.getTopics();
  };

  @action getTopics = async () => {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const params = {
        sortBy: this.sortType,
        sentiment: this.sentimentType.join(',')
      };

      const data = {
        date: {
          gte: this.gte,
          lte: this.lte
        },
        ...(this.postType !== 'all' && { postType: this.postType }),
        ...(this.womType !== 'all' && { womType: this.womType }),
        ...(this.category.length > 0 && { category: this.category.map((el) => el.id) }),
        ...(this.website.length > 0 && { website: this.website.map((el) => el.id) }),
        ...(this.channel.length > 0 && { channel: this.channel.map((el) => el.id) }),
        ...(this.industry && { industry: true }),
        ...(this.featureCategory.id && { featureCategory: this.featureCategory.id }),
        ...(this.feature.id && { feature: this.feature.id }),
        ...(this.term.name && {
          term: {
            name: this.term.name,
            score: this.term.score,
            count: this.term.count
          }
        }),
        ...((this.mindShare.mentionRatio || this.mindShare.supportRatio) && {
          '7p': {
            ...(this.mindShare.mentionRatio ? { mentionRatio: this.mindShare.mentionRatio } : {}),
            ...(this.mindShare.supportRatio ? { supportRatio: this.mindShare.supportRatio } : {})
          }
        }),
        ...(this.hasLevel && {
          search: {
            ...(this.brand.length >= 1 && { level1: this.brand.map((el) => el.id) }),
            ...(this.series.length >= 1 && { tag: this.series.map((el) => ({ keyId: el.keyId, valueId: el.valueId })) }),
            ...(this.product.length >= 1 && { level3: this.product.map((el) => el.id) })
          }
        })
      };
      const { summary, list, searchId } = await TopicService.getTopicsWithPid(this.package.id, params, data);

      runInAction(() => {
        const { commentCount, postCount, totalCount } = summary;
        this.totalCount = totalCount;
        this.postCount = postCount;
        this.commentCount = commentCount;

        this.searchId = searchId;

        this.topicList.push(...list.map((el) => new ListTopicCardViewModel({ ...el, canEditSentiment: false })));

        this.activePage = 1;
      });

    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action onPaging = async () => {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    try {
      const params = {
        sortBy: this.sortType,
        sentiment: this.sentimentType.join(','),
        page: this.activePage + 1
      };

      const { list } = await TopicService.getNextPageTopicsWithPid(this.package.id, this.searchId, params);
      runInAction(() => {
        this.activePage += 1;
        this.topicList.push(...list.map((el) => new ListTopicCardViewModel(el)));
      });

    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
  @action onDownload = async () => {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.isDownload = false;

    try {
      const params = {
        all: true,
        sortBy: this.sortType,
        sentiment: this.sentimentType.join(',')
      };

      const { post } = await TopicService.getDownloadTopicsWithPid(this.package.id, this.searchId, params, this.userType);
      runInAction(() => {
        const data = [...post];
        const header = TOPICS_CSV_TITLE_ROW.filter((el) => this.userType !== 'customer' || el.key !== 'content').map((el) => el.key);
        const workSheet = xlsx.utils.json_to_sheet(data, { header });
        xlsx.utils.sheet_add_aoa(workSheet, [TOPICS_CSV_TITLE_ROW.filter((el) => this.userType !== 'customer' || el.key !== 'content').map((el) => el.header)], { origin: 'A1' });
        workSheet['!cols'] = header.map((_, i) => ({ width: i === 4 || (i === 5 && this.userType !== 'customer') ? 50 : 15 }));
        const wordbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wordbook, workSheet, '話題列表');
        xlsx.writeFile(wordbook, `${this.package.name}-${this.chartName.split('/')[this.chartName.split('/').length - 1]}-話題列表-${this.gte} ~ ${this.lte}.xlsx`);
      });

      if (this.userType === 'customer') {
        await DownloadService.sendDownloadAlert({
          topicsCount: post.length,
          date: `${this.gte} ~ ${this.lte}`,
          keyword: this.chartName
        });
      }

    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}
