import API from 'src/apis';

import OpinionModel from 'src/models/response/Opinion';
import OpinionDetailModel from 'src/models/response/OpinionDetail';
import DownloadOpinionModel from 'src/models/response/DownloadOpinionModel';
import OpinionChartModel from 'src/models/response/charts/OpinionChart';

export default class OpinionService {
  static async getOpinions(data, params) {
    const res = await API.opinion.getOpinions(data, params);
    return OpinionModel.fromRes(res.data.result);
  }

  static async getResources(data) {
    const res = await API.opinion.getResources(data);

    return OpinionModel.fromRes(res.data.result);
  }

  static async getDownloadOpinions(data, params, userType) {
    const res = await API.opinion.getDownloadOpinions(data, params);

    return DownloadOpinionModel.fromRes(res.data.result, userType);
  }

  static async getOpinion(vocKey, page) {
    const res = await API.opinion.getOpinion(vocKey, page);

    return OpinionDetailModel.fromRes(res.data.result);
  }

  static async getOpinionChart(data) {
    const res = await API.opinion.getOpinionChart(data);

    return OpinionChartModel.fromRes(res.data.result);
  }
}
