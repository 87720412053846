import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';

import i18n from 'src/i18n';

import BlueEye from 'src/assets/BlueEye.svg';

import styles from './styles.module.scss';

@observer
class SearchTopicCard extends React.Component {
  render() {
    const { order, viewModel, userType } = this.props;
    return (
      <div className={styles.resCard}>
        <a
          className={styles.vocTitle}
          href={viewModel.url}
          target="_blank"
          rel="noreferrer"
        >
          {`${order + 1}. ${viewModel.vocTitle}`}
        </a>
        <div className={styles.vocInfo}>
          <div className={styles.infoItem}>
            {`${i18n.t('topic_card_source')}： ${viewModel.website}[${viewModel.channel}]`}
          </div>
          <div className={styles.infoItem}>
            {`${i18n.t('topic_card_author')}： ${viewModel.author}`}
          </div>
          <div className={styles.infoItem}>
            {`${i18n.t('topic_card_date')}： ${dayjs(viewModel.date).format('YYYY-MM-DD HH:mm:ss')}`}
          </div>
          <div className={styles.infoItem}>
            {`${i18n.t('topic_card_view_count')}： ${viewModel.viewCount}`}
          </div>
          <div className={styles.infoItem}>
            {`${i18n.t('topic_card_share_count')}： ${viewModel.shareCount}`}
          </div>
          {/* <div className={styles.infoItem}>
            {`${i18n.t('topic_card_comment_count')}： ${viewModel.commentCount} ${i18n.t('topic_card_comment_count_unit')}`}
          </div> */}
        </div>
        <div className={styles.contentContainer}>
          {viewModel.content}
        </div>
        <div className={styles.footerContainer}>
          <div className={styles.postType}>
            {viewModel.replyId ? `回文${viewModel.replyId}` : '主文'}
          </div>
          {
            userType !== 'customer' && (
              <a
                className={styles.topicAction}
                href={viewModel.href}
                target="_blank"
                rel="noreferrer"
              >
                <img src={BlueEye} alt="" />
                <div className={styles.topicTitle}>
                  查看庫存頁
                </div>
              </a>
            )
          }
        </div>
      </div>
    );
  }
}

SearchTopicCard.propTypes = {
  order: PropTypes.number,
  viewModel: PropTypes.object,
  userType: PropTypes.string
};

SearchTopicCard.defaultProps = {
  order: 0,
  viewModel: {},
  userType: null
};


export default SearchTopicCard;
