import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Empty, Button, Tooltip } from 'antd';
import { DownloadOutlined, SaveOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import i18n from 'src/i18n';

import DayTrend from './charts/DayTrend';
import MonthTrend from './charts/MonthTrend';
import Category from './charts/Category';
import Channel from './charts/Channel';
import Sentiment from './charts/Sentiment';
import Word from './charts/Word';

import styles from './styles.module.scss';

@observer
class OpinionChartSection extends React.Component {
  render() {
    const { viewModel } = this.props;
    if (viewModel.volume === 0) {
      return (
        <div className={styles.emptyContainer}>
          <Empty description={viewModel.isLoading ? '請稍候' : '無符合資料'} />
        </div>
      );
    }
    return (
      <div className={styles.chartSectionContainer}>
        <div className={styles.headerRow}>
          <div className={styles.titleText}>
            {i18n.t('opinion_chart_summary_title')}
          </div>
          <div className={styles.actionBtns}>
            <Button
              className={styles.btn}
              onClick={viewModel.onDownloadAllData}
            >
              {i18n.t('opinion_chart_download_all_data')}
            </Button>
            <Button
              className={styles.btn}
              onClick={viewModel.onDownloadAllChart}
            >
              {i18n.t('opinion_chart_download_all_chart')}
            </Button>
          </div>
        </div>
        <div className={styles.summaryContainer}>
          <div className={styles.head}>
            {i18n.t('opinion_chart_summary_keyword')}
          </div>
          <div className={styles.head}>
            {i18n.t('opinion_chart_summary_volume')}
          </div>
          <div className={styles.head}>
            {i18n.t('opinion_chart_summary_sentiment')}
          </div>
          <div className={styles.head}>
            {i18n.t('opinion_chart_summary_category')}
          </div>
          <div className={styles.head}>
            {i18n.t('opinion_chart_summary_website')}
          </div>
          <div className={styles.head}>
            {i18n.t('opinion_chart_summary_channel')}
          </div>
          <div className={styles.head}>
            {i18n.t('opinion_chart_summary_term')}
          </div>
          <div className={styles.head}>
            {i18n.t('opinion_chart_summary_topic')}
          </div>
          <div className={clsx(styles.content, !viewModel.keyword && styles.empty)}>
            {viewModel.keyword || i18n.t('opinion_chart_summary_empty')}
          </div>
          <div className={clsx(styles.content, !viewModel.volume && styles.empty)}>
            {viewModel.volume || i18n.t('opinion_chart_summary_empty')}
          </div>
          <div className={clsx(styles.content, !viewModel.pnRatio && styles.empty)}>
            {viewModel.pnRatio || i18n.t('opinion_chart_summary_empty')}
          </div>
          <div className={clsx(styles.content, !viewModel.hotCategory && styles.empty)}>
            {viewModel.hotCategory || i18n.t('opinion_chart_summary_empty')}
          </div>
          <div className={clsx(styles.content, !viewModel.hotWebsite && styles.empty)}>
            {viewModel.hotWebsite || i18n.t('opinion_chart_summary_empty')}
          </div>
          <div className={clsx(styles.content, !viewModel.hotChannel && styles.empty)}>
            {viewModel.hotChannel || i18n.t('opinion_chart_summary_empty')}
          </div>
          <div className={clsx(styles.content, !viewModel.hotWord && styles.empty)}>
            {viewModel.hotWord || i18n.t('opinion_chart_summary_empty')}
          </div>
          <div className={clsx(styles.content, !viewModel.hotTopic && styles.empty)}>
            {viewModel.hotTopic
              ? (
                <a
                  className={styles.link}
                  href={viewModel.hotTopicUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {viewModel.hotTopic}
                </a>
              )
              : i18n.t('opinion_chart_summary_empty')}
          </div>
        </div>
        <DayTrend viewModel={viewModel.dayTrend} />
        <MonthTrend viewModel={viewModel.monthTrend} />
        <Category viewModel={viewModel.category} />
        <Channel viewModel={viewModel.channel} />
        <Sentiment viewModel={viewModel.sentiment} />
        <Word viewModel={viewModel.word} />
      </div>
    );
  }
}

OpinionChartSection.propTypes = {
  viewModel: PropTypes.object
};

OpinionChartSection.defaultProps = {
  viewModel: {}
};

export default OpinionChartSection;
