import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import i18n from 'src/i18n';

import BlueEye from 'src/assets/BlueEye.svg';
import userStore from 'src/stores/userStore';

import SentimentItem from '../SentimentItem';

import styles from './styles.module.scss';

@observer
class ListTopicCard extends React.Component {
  render() {
    const { order, viewModel, canChangeSentiment, userType } = this.props;
    return (
      <div className={styles.resCard}>
        <a href={viewModel.url} target="_blank" rel="noreferrer" className={styles.vocTitle}>
          {`${order + 1}. ${viewModel.vocTitle}`}
        </a>
        <div className={styles.vocInfo}>
          <div className={styles.infoItem}>
            {`${i18n.t('topic_card_source')}： ${viewModel.website}[${viewModel.channel}]`}
          </div>
          <div className={styles.infoItem}>
            {`${i18n.t('topic_card_author')}： ${viewModel.author}`}
          </div>
          <div className={styles.infoItem}>
            {`${i18n.t('topic_card_date')}： ${dayjs(viewModel.date).format('YYYY-MM-DD HH:mm:ss')}`}
          </div>
          <div className={styles.infoItem}>
            {`${i18n.t('topic_card_view_count')}： ${viewModel.viewCount}`}
          </div>
          <div className={styles.infoItem}>
            {`${i18n.t('topic_card_share_count')}： ${viewModel.shareCount}`}
          </div>
          {/* <div className={styles.infoItem}>
            {`${i18n.t('topic_card_comment_count')}： ${viewModel.commentCount} ${i18n.t('topic_card_comment_count_unit')}`}
          </div> */}
        </div>
        <div className={styles.contentContainer}>
          {viewModel.content}
        </div>
        <div className={styles.footerContainer}>
          <div className={styles.footerInfo}>
            <SentimentItem
              prefix={viewModel.hitPostType}
              sentiment={viewModel.sentiment}
              onSentimentChange={viewModel.onSentimentChange}
              canChangeSentiment={canChangeSentiment}
            />
            <div className={styles.hitInfo}>
              <div className={styles.hitItem}>
                {`聲量：${viewModel.womCount}`}
              </div>
              <div className={styles.hitItem}>
                {`品牌：${viewModel.brandHit?.name}`}
              </div>
              <div className={styles.hitItem}>
                {`系列：${viewModel.seriesHit?.name}`}
              </div>
              <div className={styles.hitItem}>
                {`產品：${viewModel.productHit?.name}`}
              </div>

            </div>
          </div>
          {
            userType !== 'customer' && (
              <a className={styles.topicAction} href={viewModel.href} target="_blank" rel="noreferrer">
                <img src={BlueEye} alt="" />
                <div className={styles.topicTitle}>
                  {i18n.t('topic_card_view_cache_page')}
                </div>
              </a>
            )
          }
        </div>
      </div>
    );
  }
}

ListTopicCard.propTypes = {
  order: PropTypes.number,
  viewModel: PropTypes.object,
  canChangeSentiment: PropTypes.bool,
  userType: PropTypes.string
};

ListTopicCard.defaultProps = {
  order: 0,
  viewModel: {},
  canChangeSentiment: true,
  userType: null
};


export default ListTopicCard;
