import { request, getHost, getHeaders } from './utils';

/**
 * 發送下載提醒
 * @param {SendDownloadAlertData} data
 */
/**
 * @typedef {Object} SendDownloadAlertData
 * @property {number} topicsCount 下載數量
 * @property {string} date 起迄時間
 * @property {string} keyword 關鍵字
 * @property {string} userId 使用者ID - 會使用目前 activeUser 的 userId (因為token僅能取得該token的user資料，但內部使用者可以模擬其他帳號)
 * @property {string} userName 使用者名稱 - 會使用目前 activeUser 的 userName (因為token僅能取得該token的user資料，但內部使用者可以模擬其他帳號)
 */
export const sendDownloadAlert = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/download/alert`,
    headers: getHeaders(),
    data
  };
  return request(options);
};
