import { SOURCE_CATEGORY_TYPE } from 'src/consts';

export default class DownloadOpinionModel {
  constructor(data, userType) {
    const {
      post
    } = data;

    this.post = post.map((el, i) => ({
      order: i + 1,
      title: el.title,
      category: SOURCE_CATEGORY_TYPE[el.category],
      website: el.website,
      channel: el.channel,
      author: el.author,
      date: el.date,
      ...(userType !== 'customer' && { content: el.content }),
      viewCount: el.viewCount,
      shareCount: el.shareCount,
      commentCount: el.commentCount,
      postType: el.replyId !== 0 ? `回文${el.replyId < 99999 ? el.replyId : ''}` : '主文',
      url: el.url
    }));
  }

  static fromRes(data, userType) {
    return new DownloadOpinionModel(data, userType);
  }
}
