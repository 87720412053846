import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import * as xlsx from 'xlsx';

import { ROUTES, OPINION_CSV_TITLE_ROW } from 'src/consts';
import { menuData } from 'src/consts/menu';
import userStore from 'src/stores/userStore';
import AuthenticateService from 'src/services/authenticate';
import OpinionService from 'src/services/opinion';
import optionsStore from 'src/stores/optionsStore';
import DownloadService from 'src/services/download';

import PopoverSelectViewModel from '../PopoverSelect/viewModel';
import SinglePeriodDatePickerViewModel from '../SinglePeriodDatePicker/viewModel';
import SearchTopicCardViewModel from '../SearchTopicCard/viewModel';
import OpinionChartSectionViewModel from './sections/chart/viewModel';


export default class CustomizeLayoutViewModel {
  @observable keyword = '';
  @observable keywordCache = '';

  @observable customerKeyword = '';
  @observable customerKeywordCache = '';

  @observable isDrawerOpen = false;
  @observable isProcess = false;

  @observable filterPost = 'all';
  @observable filterWordOfMouth = 'all';
  @observable category = new PopoverSelectViewModel(this, 'category');
  @observable website = new PopoverSelectViewModel(this, 'website');
  @observable channel = new PopoverSelectViewModel(this, 'channel');
  @observable totalCount = 0;
  @observable countCache = 0;

  @observable datePickerViewModel = new SinglePeriodDatePickerViewModel();

  @observable sortType = 'commentCount';

  @observable resList = [];
  @observable page = 1;

  @observable isDownload = false;
  @observable downloadList = [];
  @observable downloadFileName = '';

  @observable activeSection = 'topic'; // > topic, chart
  @observable topicNeedReload = false;
  @observable chartNeedReload = true;

  @observable chartViewModel = new OpinionChartSectionViewModel();

  @computed get isShowCleanAll() {
    return this.filterPost !== 'all' || this.filterWordOfMouth !== 'all' || this.category.selected.id || this.website.selected.id || this.channel.selected.id;
  }

  @computed get searchCustomersRes() {
    return !this.customerKeywordCache ? userStore.customerList : userStore.customerList.filter((el) => el.name?.toLowerCase().includes(this.customerKeywordCache.toLowerCase()) || el.account.toLowerCase().includes(this.customerKeywordCache.toLowerCase()));
  }

  @computed get availableSideMenu() {
    return menuData;
  }

  @computed get filter() {
    return {
      category: this.category.selected.id,
      website: this.website.selected.id,
      channel: this.channel.selected.id
    };
  }

  @computed get hasNext() {
    return this.totalCount > this.page * 50;
  }

  @computed get changePasswordUrl() {
    if (/:\d{4,}$/.test(window.location.origin)) {
      return 'https://buzz-sso-d90d217a.miraclemobile.com.tw';
    }

    // dev
    if (/d90d217a/.test(window.location.host)) {
      return 'https://buzz-sso-d90d217a.miraclemobile.com.tw';
    }

    // pro
    return 'https://sso.i-buzz-system.com/';
  }

  @computed get opinionChartSearchData() {
    const searchData = {
      keyword: this.keyword,
      date: {
        gte: this.datePickerViewModel.date[0].format('YYYY-MM-DD'),
        lte: this.datePickerViewModel.date[1].format('YYYY-MM-DD')
      },
      ...(this.filterPost !== 'all' && { postType: this.filterPost }),
      ...(this.filterWordOfMouth !== 'all' && { womType: this.filterWordOfMouth })
    };
    if (this.filter.category) {
      if (this.filter.website) {
        if (this.filter.channel) {
          searchData.channel = [{
            id: this.channel.selected.id,
            name: this.channel.selected.name
          }];
        } else {
          searchData.website = [this.filter.website];
        }
      } else {
        searchData.category = [this.filter.category];
      }
    }
    return searchData;
  }

  constructor() {
    makeObservable(this);
  }

  onPackageBtnClick = (router) => {
    router.navigate(ROUTES.PackagesManage);
  };

  // > topic search

  @action onSearchChange = (e) => {
    this.keyword = e.target.value;
  };

  @action onSearchSubmit = async () => {
    if (!this.keyword.trim() || this.isProcess) {
      return;
    }

    this.isProcess = true;

    if (this.keyword === this.keywordCache) {
      if (!this.isDrawerOpen) {
        this.onDrawerOpen();
        setTimeout(() => {
          this.chartViewModel.word?.onDraw();
        }, 500);
        this.isProcess = false;
      }
      return;
    }

    this.keywordCache = this.keyword;
    this.resList = [];
    this.page = 1;

    this.filterPost = 'all';
    this.filterWordOfMouth = 'all';
    this.sortType = 'commentCount';

    this.category.onCleanSelect();
    this.website.onCleanSelect();
    this.channel.onCleanSelect();

    // ! remove at 20240813
    // this.datePickerViewModel.resetDate();
    // this.activeSection = 'topic';

    this.chartViewModel.clearSummary();

    if (!this.isDrawerOpen) {
      this.onDrawerOpen();
    }
    if (this.activeSection === 'topic') {
      await this.getOpinions();
      runInAction(() => {
        this.chartNeedReload = true;
      });
    } else {
      await this.chartViewModel.getChartContent(this.opinionChartSearchData);
      runInAction(() => {
        this.topicNeedReload = true;
        this.isProcess = false;
      });
    }
  };

  @action onKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSearchSubmit();
    }
  };

  @action getOpinions = async () => {
    optionsStore.setLoading('getOpinion');
    try {
      const searchData = {
        keyword: this.keyword,
        date: {
          gte: this.datePickerViewModel.date[0].format('YYYY-MM-DD'),
          lte: this.datePickerViewModel.date[1].format('YYYY-MM-DD')
        },
        ...(this.filterPost !== 'all' && { postType: this.filterPost }),
        ...(this.filterWordOfMouth !== 'all' && { womType: this.filterWordOfMouth })
      };
      if (this.filter.category) {
        if (this.filter.website) {
          if (this.filter.channel) {
            searchData.channel = [this.filter.channel];
          } else {
            searchData.website = [this.filter.website];
          }
        } else {
          searchData.category = [this.filter.category];
        }
      }
      const searchParams = {
        page: this.page,
        sortBy: this.sortType
      };
      const {
        category,
        website,
        channel,
        summary,
        post
      } = await OpinionService.getOpinions(searchData, searchParams);

      runInAction(() => {
        // > 使用TotalCount 取代手動計算的結果
        if (category.length > 0) {
          this.category.updateList(category);
        }
        if (website.length > 0) {
          this.website.updateList(website);
        }
        if (channel.length > 0) {
          this.channel.updateList(channel);
        }
        if (category.length === 0) {
          this.totalCount = this.countCache;
        }
        this.totalCount = summary;
        post.forEach((el) => {
          this.resList.push(new SearchTopicCardViewModel(el));
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isProcess = false;
        optionsStore.setCompleted('getOpinion');
      });
    }
  };

  @action onScrollEnd = async () => {
    this.page += 1;
    await this.getOpinions();
  };

  @action onSelectCallback = async (type, target) => {
    optionsStore.setLoading('onSelectCallback');
    if (type === 'channel') {
      this.countCache = target.count;
      optionsStore.setCompleted('onSelectCallback');
      return;
    }
    const searchData = {
      keyword: this.keyword,
      date: {
        gte: this.datePickerViewModel.date[0].format('YYYY-MM-DD'),
        lte: this.datePickerViewModel.date[1].format('YYYY-MM-DD')
      },
      ...(this.filterPost !== 'all' && { postType: this.filterPost }),
      ...(this.filterWordOfMouth !== 'all' && { womType: this.filterWordOfMouth })
    };
    switch (type) {
      case 'category':
        searchData.category = [target.id];
        this.countCache = target.count;

        this.website.onCleanSelect();
        this.channel.onCleanSelect();
        break;
      case 'website':
        searchData.website = [target.id];
        this.countCache = target.count;

        this.channel.onCleanSelect();
        break;
      default:
        break;
    }

    try {
      const {
        website,
        channel
      } = await OpinionService.getResources(searchData);

      runInAction(() => {
        switch (type) {
          case 'category':
            this.website.updateList(website);
            break;
          case 'website':
            this.channel.updateList(channel);
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('onSelectCallback');
    }

  };

  @action onFilterSubmit = async () => {
    this.resList = [];
    this.page = 1;
    this.chartViewModel.clearSummary();

    if (this.activeSection === 'topic') {
      await this.getOpinions();
      runInAction(() => {
        this.chartNeedReload = true;
      });
    } else {
      await this.chartViewModel.getChartContent(this.opinionChartSearchData);
      runInAction(() => {
        this.topicNeedReload = true;
      });
    }
  };

  // > add for list content reload
  @action onListContentReload = async () => {
    optionsStore.setLoading('reloadList');
    const searchData = {
      keyword: this.keyword,
      date: {
        gte: this.datePickerViewModel.date[0].format('YYYY-MM-DD'),
        lte: this.datePickerViewModel.date[1].format('YYYY-MM-DD')
      },
      ...(this.filterPost !== 'all' && { postType: this.filterPost }),
      ...(this.filterWordOfMouth !== 'all' && { womType: this.filterWordOfMouth })
    };
    // > 更新第一層 category
    try {
      const { category } = await OpinionService.getResources(searchData);
      runInAction(() => {
        this.category.updateList(category);
      });
    } catch (error) {
      console.log(error);
    }
    // > 更新第二層 website
    if (!this.category.selected.id) {
      optionsStore.setCompleted('reloadList');
      return;
    }
    // > add list in
    const websiteSearchData = {
      ...searchData,
      category: [this.category.selected.id]
    };

    try {
      const { website } = await OpinionService.getResources(websiteSearchData);
      runInAction(() => {
        this.website.updateList(website);
      });
    } catch (error) {
      console.log(error);
    }

    // > 更新第三層 channel
    if (!this.website.selected.id) {
      optionsStore.setCompleted('reloadList');
      return;
    }
    const channelSearchData = {
      ...searchData,
      website: [this.website.selected.id]
    };

    try {
      const { channel } = await OpinionService.getResources(channelSearchData);
      runInAction(() => {
        this.channel.updateList(channel);
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('reloadList');
    }
  };

  @action onDateChange = async () => {
    this.resList = [];
    this.page = 1;
    this.chartViewModel.clearSummary();

    // > date change 全部的 list content 都要重算
    this.onListContentReload();

    if (this.activeSection === 'topic') {
      await this.getOpinions();
      runInAction(() => {
        this.chartNeedReload = true;
      });
    } else {
      await this.chartViewModel.getChartContent(this.opinionChartSearchData);
      runInAction(() => {
        this.topicNeedReload = true;
      });
    }
  };

  @action onDownloadClick = async () => {
    if (this.isProcess) {
      return;
    }
    this.isProcess = true;
    optionsStore.setLoading('getDownload');

    const userType = userStore.userType;
    try {
      const searchData = {
        keyword: this.keyword,
        date: {
          gte: this.datePickerViewModel.date[0].format('YYYY-MM-DD'),
          lte: this.datePickerViewModel.date[1].format('YYYY-MM-DD')
        },
        ...(this.filterPost !== 'all' && { postType: this.filterPost })
      };
      if (this.filter.category) {
        if (this.filter.website) {
          if (this.filter.channel) {
            searchData.channel = [this.filter.channel];
          } else {
            searchData.website = [this.filter.website];
          }
        } else {
          searchData.category = [this.filter.category];
        }
      }
      const searchParams = {
        sortBy: this.sortType
      };

      const { post } = await OpinionService.getDownloadOpinions(searchData, searchParams, userType);
      runInAction(() => {
        const data = [...post];
        const header = OPINION_CSV_TITLE_ROW
          .filter((el) => userType !== 'customer' || el.key !== 'content')
          .map((el) => el.key);
        const workSheet = xlsx.utils.json_to_sheet(data, { header });
        xlsx.utils.sheet_add_aoa(workSheet, [OPINION_CSV_TITLE_ROW.filter((el) => userType !== 'customer' || el.key !== 'content').map((el) => el.header)], { origin: 'A1' });
        workSheet['!cols'] = [10, 80, ...(userType !== 'customer' ? [80] : []), 30, 20, 10, 20, 20, 20, 10, 10, 10, 60].map((el) => ({ width: el }));
        const wordbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wordbook, workSheet, '話題列表');
        xlsx.writeFile(wordbook, `${this.keyword}-${this.datePickerViewModel.date[0].format('YYYY-MM-DD')}~${this.datePickerViewModel.date[1].format('YYYY-MM-DD')}.xlsx`);
      });
      if (userType === 'customer') {
        await DownloadService.sendDownloadAlert({
          topicsCount: post.length,
          date: `${this.datePickerViewModel.date[0].format('YYYY-MM-DD')}_${this.datePickerViewModel.date[1].format('YYYY-MM-DD')}`,
          keyword: this.keyword
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isProcess = false;
        optionsStore.setCompleted('getDownload');
      });
    }
  };

  // > customer search

  @action onCustomerSearchChange = (e) => {
    this.customerKeyword = e.target.value;
    if (!this.customerKeyword.trim()) {
      this.customerKeywordCache = '';
    }
  };

  @action onCustomerSearchSubmit = () => {
    this.customerKeywordCache = this.customerKeyword;
  };

  @action onCustomerSearchKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onCustomerSearchSubmit();
    }
  };

  @action onOpenChange = () => {
    this.customerKeyword = '';
    this.customerKeywordCache = '';
  };


  @action onDrawerOpen = () => {
    this.isDrawerOpen = true;
  };

  @action onDrawerClose = () => {
    this.isDrawerOpen = false;
  };

  @action onPostTypeChange = async (e) => {
    this.filterPost = e;

    const searchData = {
      keyword: this.keyword,
      date: {
        gte: this.datePickerViewModel.date[0].format('YYYY-MM-DD'),
        lte: this.datePickerViewModel.date[1].format('YYYY-MM-DD')
      },
      ...(this.filterPost !== 'all' && { postType: this.filterPost }),
      ...(this.filterWordOfMouth !== 'all' && { womType: this.filterWordOfMouth })
    };

    try {
      const { category } = await OpinionService.getResources(searchData);

      runInAction(() => {
        this.category.updateList(category);

        this.category.onCleanSelect();
        this.website.onCleanSelect();
        this.channel.onCleanSelect();
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onWOMChange = async (e) => {
    this.filterWordOfMouth = e;

    const searchData = {
      keyword: this.keyword,
      date: {
        gte: this.datePickerViewModel.date[0].format('YYYY-MM-DD'),
        lte: this.datePickerViewModel.date[1].format('YYYY-MM-DD')
      },
      ...(this.filterPost !== 'all' && { postType: this.filterPost }),
      ...(this.filterWordOfMouth !== 'all' && { womType: this.filterWordOfMouth })
    };

    try {
      const { category } = await OpinionService.getResources(searchData);

      runInAction(() => {
        this.category.updateList(category);

        this.category.onCleanSelect();
        this.website.onCleanSelect();
        this.channel.onCleanSelect();
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onSourceCategoryChange = (e) => {
    this.filterSourceCategory = e;
  };

  @action onSourceWebsiteChange = (e) => {
    this.filterSourceWebsite = e;
  };

  @action onSourceChannelChange = (e) => {
    this.filterSourceChannel = e;
  };

  @action cleanAllFilter = async () => {
    this.filterPost = 'all';
    this.filterWordOfMouth = 'all';
    this.category.onCleanSelect();
    this.website.onCleanSelect();
    this.channel.onCleanSelect();

    this.resList = [];
    this.page = 1;
    this.chartViewModel.clearSummary();

    if (this.activeSection === 'topic') {
      await this.getOpinions();
      runInAction(() => {
        this.chartNeedReload = true;
      });
    } else {
      await this.chartViewModel.getChartContent(this.opinionChartSearchData);
      runInAction(() => {
        this.topicNeedReload = true;
      });
    }
  };

  @action onSortTypeChange = async (e) => {
    this.sortType = e;

    this.resList = [];
    this.page = 1;
    await this.getOpinions();
  };

  @action onLogout = async (router) => {
    try {
      await AuthenticateService.logout();
      runInAction(() => {
        router.navigate('/login', { replace: true });
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onSectionChange = async (section) => {
    this.activeSection = section;
    if (section === 'chart' && this.chartNeedReload) {
      this.chartViewModel.clearSummary();
      await this.chartViewModel.getChartContent(this.opinionChartSearchData);
      runInAction(() => {
        this.chartNeedReload = false;
      });
    }

    if (section === 'topic' && this.topicNeedReload) {
      this.resList = [];
      this.page = 1;
      await this.getOpinions();
      runInAction(() => {
        this.topicNeedReload = false;
      });
    }
  };
}
